import AverageStats from '@eq/components/AverageStats'
import Container from '@eq/components/Container'
import { ChainHero } from '@eq/components/Hero'
import Layout from '@eq/components/Layout'
import { PublicRequestsPageList } from '@eq/components/PublicRequestsPage'
import SelectSector from '@eq/components/SelectSector'
import models from '@eq/models'
import { cn } from '@eq/utils/classnames'
import { Typography } from 'antd'
import Button, { ButtonProps } from 'antd-button-color'
import { InferGetStaticPropsType } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormattedMessage, useIntl } from 'react-intl'
import { getFeatureFlags } from './api/feature-flags'
import { getAllPublicRequests } from './api/requests/[uid]'
import Head from 'next/head'

const HeroButton = ({ className = '', href = '', ...props }: ButtonProps) => {
  return (
    <Link href={href}>
      <a>
        <Button
          className={cn('h-auto hover:bg-black-400 font-bold tracking-wider bg-gradient-to-br', className)}
          size="large"
          type="dark"
          {...props}
        />
      </a>
    </Link>
  )
}

export default function HomePage(props: InferGetStaticPropsType<typeof getStaticProps>) {
  const intl = useIntl()
  const { push } = useRouter()
  return (
    <Layout>
      <Head>
        <title>Welcome to EezyQuote</title>
      </Head>
      <ChainHero title={<FormattedMessage defaultMessage="How it <highlight>Works</highlight>" />}>
        {/* <div className="mt-8">
          <HeroButton href={'/request/create'} className="text-warning-light hover:text-yellow-300 w-full sm:w-auto">
            <span className="py-2 px-4">{intl.formatMessage({ defaultMessage: 'CREATE A RFQ' })}</span>
          </HeroButton>
          <span className="sm:hidden">
            <Divider className="mt-4 ">OR</Divider>
          </span>
          <HeroButton href={'/requests'} className="sm:hidden text-warning-light hover:text-yellow-300 w-full" block>
            <span className="py-2 px-4">{intl.formatMessage({ defaultMessage: 'VIEW ALL REQUESTS' })}</span>
          </HeroButton>
        </div> */}
      </ChainHero>

      <Container className="pb-20">
        <Typography.Title level={2}>
          <FormattedMessage defaultMessage="Create a Request" />
        </Typography.Title>
        <SelectSector
          onSelect={(key) => {
            if (key == 'transportProduct') {
              return push(`/request/create-transport`)
            } else {
              return push(`/request/create?type=${key}`)
            }
          }}
        />
        <div className="relative pt-20">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            A better way to receive quotes
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            A unified system for finding and purchasing parts. Buyers send requests and suppliers respond with quotes.
          </p>
        </div>
      </Container>

      <Container accent className="py-20">
        <Typography.Title level={2}>
          <FormattedMessage defaultMessage="Latest Stats" />
        </Typography.Title>
        <AverageStats {...props.stats} />
      </Container>

      <Container className="py-20">
        <div className="flex justify-between items-center mb-2">
          <Typography.Title level={2} className="mb-0">
            <FormattedMessage defaultMessage="Latest Requests" />
          </Typography.Title>
          <HeroButton href={'/request/create'} size="large" type="info" className="bg-gradient-to-br from-info to-blue-800 via-blue-600">
            {intl.formatMessage({ defaultMessage: 'Create a request' })}
          </HeroButton>
        </div>
        <PublicRequestsPageList className="space-y-8 pb-10" requests={props.latestRequests} quoteDisabled />
        <div className="text-center">
          <Link href="/requests">
            <a>
              <Button size="large" className="w-44 max-w-full">
                {intl.formatMessage({ defaultMessage: 'Show more' })}
              </Button>
            </a>
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export async function getStaticProps() {
  const stats = await models.stats.getLandingStats()

  const { requests } = await getAllPublicRequests(1, 10)
  const features = await getFeatureFlags()

  return {
    props: {
      stats,
      latestRequests: requests,
      features,
    },
    revalidate: 1,
  }
}
