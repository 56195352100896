import { ReactNode, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { HiOutlineUsers, HiOutlineCursorClick } from 'react-icons/hi'
import { RiMailSendFill } from 'react-icons/ri'

interface AverageStatsProps {
  totalSubscribers: {
    value: number
    difference: number
  }
  totalRequests: {
    value: number
    difference: number
  }
  totalQuotations: {
    value: number
    difference: number
  }
}

const Difference = (props: { value: number }) => {
  const less = props.value < 0
  const equal = props.value == 0

  const colors = less
    ? {
        dark: 'text-red-600',
        light: 'text-red-500',
      }
    : equal
    ? {
        dark: 'text-gray-600',
        light: 'text-gray-500',
      }
    : {
        dark: 'text-green-600',
        light: 'text-green-500',
      }

  const message = useMemo(() => {
    if (less) return <FormattedMessage defaultMessage="Decreased by" />
    return <FormattedMessage defaultMessage="Increased by" />
  }, [less])

  return (
    <div className={`ml-2 flex items-baseline text-sm font-semibold ${colors.dark}`}>
      <svg className={`self-center flex-shrink-0 h-5 w-5 ${colors.light}`} fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
        <path
          fillRule="evenodd"
          d={
            less
              ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
              : 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
          }
          clipRule="evenodd"
        />
      </svg>
      <span className="sr-only">{message}</span>
      {Math.abs(props.value)}
    </div>
  )
}

const Card = (props: { value: number; difference: number; title: ReactNode; icon: ReactNode }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="flex items-center">
          <div className="flex-shrink-0 bg-info rounded-md p-3">
            <div className="h-6 w-6 text-white text-xl text-center">{props.icon}</div>
          </div>
          <div className="ml-5 w-0 flex-1">
            <dt className="text-md font-medium text-gray-500 truncate">{props.title}</dt>
            <dd className="flex items-baseline">
              <div className="text-2xl font-semibold text-gray-900">{props.value}</div>
              <Difference value={props.difference} />
            </dd>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function AverageStats(props: AverageStatsProps) {
  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 xs:grid-cols-2 sm:grid-cols-3">
      <Card title={<FormattedMessage defaultMessage="Total Subscribers" />} icon={<HiOutlineUsers />} {...props.totalSubscribers} />
      <Card title={<FormattedMessage defaultMessage="Total Requests" />} icon={<HiOutlineCursorClick />} {...props.totalRequests} />
      <Card title={<FormattedMessage defaultMessage="Total Quotations" />} icon={<RiMailSendFill />} {...props.totalQuotations} />
    </dl>
  )
}
