import Clickable from '@eq/components/Clickable'
import { productMessages } from '@eq/i18n/common'
import { cn } from '@eq/utils/classnames'
import { ProductVariantTypes } from '@eezyquote/server.schema'
import { Badge, Tooltip } from 'antd'
import Image from 'next/image'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useFeatures } from '../FeatureFlags'

interface SelectSectorProps {
  onSelect?: (sector?: ProductVariantTypes | undefined) => void
  filters?: typeof baseSectors[number]['key'][]
  suffix?: string
}

export const selectSectorProps = {
  machineryProduct: {
    message: productMessages('machineryProduct'),
    image: '/images/machinery-banner.jpg',
    color: '#fdaf2e',
    disabled: false,
  },
  toolsProduct: {
    message: productMessages('toolsProduct'),
    image: '/images/tools-banner.jpg',
    color: '#2694f1',
    disabled: false,
  },
  transportProduct: {
    message: productMessages('transportProduct'),
    image: '/images/transport-banner.jpg',
    color: '#604f37',
    disabled: true,
  },
}

type SectorTypes = Exclude<ProductVariantTypes, 'genericProduct'>

export const baseSectors: Array<typeof selectSectorProps[keyof typeof selectSectorProps] & { key }> = Object.keys(selectSectorProps).map(
  (key) => ({ key, ...selectSectorProps[key] })
)

const SelectSector = ({ filters = baseSectors.map((sector) => sector.key), onSelect, suffix }: SelectSectorProps) => {
  const intl = useIntl()
  const { toolsSectorEnabled } = useFeatures()

  const handleSelect = useCallback((selection?: SectorTypes) => onSelect?.(selection), [onSelect])
  const sectors = baseSectors.filter((sector) => {
    if (!toolsSectorEnabled && sector.key === 'toolsProduct') return false
    return filters.includes(sector.key)
  })

  return (
    <div
      className={cn('grid grid-cols-1 gap-6', {
        'sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3': sectors.length > 1,
      })}
    >
      {sectors.map((sector) => {
        const title = `${intl.formatMessage(sector.message)} ${suffix || intl.formatMessage({ defaultMessage: 'Sector' })}`

        return (
          <div key={sector.key} className="group">
            <Tooltip title={sector.disabled ? 'Sector is coming soon' : 'Create a request for this sector'}>
              <Badge.Ribbon text={<div className="font-medium">{title}</div>} color={sector.disabled ? '#878787' : sector.color}>
                <div className="relative w-full h-full">
                  <Clickable
                    onClick={sector.disabled ? () => null : () => handleSelect(sector.key)}
                    className={cn(
                      'rounded-lg shadow-sm overflow-hidden static col-span-1 transform   group transition duration-300 ease-in-out',
                      {
                        'pointer-events-none opacity-50 filter grayscale': sector.disabled,
                        'cursor-pointer hover:-translate-y-1': !sector.disabled,
                      }
                    )}
                  >
                    {/* <div className="absolute z-10 right-0 top-4 py-1 px-2 bg-accent-7 rounded-l group-hover:bg-info transition duration-300 ease-in-out">
                  <Typography.Title level={5} className="my-0 mx-auto text-white leading-relaxed">
                    {title}
                  </Typography.Title>
                </div> */}

                    <Image src={sector.image} alt={title} width="200" height="100" objectFit="cover" layout="responsive" />
                  </Clickable>
                  {sector.disabled && (
                    <div className="absolute center-xy top-0 left-0  italic p-2 opacity-75 text-xs">
                      {intl.formatMessage({ defaultMessage: 'Coming Soon' })}
                    </div>
                  )}
                </div>
              </Badge.Ribbon>
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}

SelectSector.messages = productMessages

export default SelectSector
