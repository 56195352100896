import { Typography } from 'antd'
import Container from '@eq/components/Container'
import Image from 'next/image'
import { ReactNode } from 'react'

interface ChainHeroProps {
  /** @title is displayed before the hero image */
  title?: ReactNode
  /** @children is displayed after the hero image */
  children?: ReactNode
  accent?: boolean
}

export default function ChainHero(props: ChainHeroProps) {
  return (
    <Container accent={props.accent} className=" py-20 border-top-0">
      <Container className="text-center space-y-4">
        <Typography.Title level={2} className="mb-12 text-4xl sm:text-5xl">
          {props.title}
        </Typography.Title>
        <div className="hidden sm:block">
          <Image src="/images/chain-hero.svg" alt="rfq process" width="750" height="308" />
        </div>
        <div className="sm:hidden mx-auto">
          <Image src="/images/chain-hero-mobile.svg" alt="rfq process" width="400" height="264" />
        </div>
        {props.children}
      </Container>
    </Container>
  )
}
